import React, { useState } from "react";
import "./style.css";
import ImageFadeIn from "react-image-fade-in";
import { NavHashLink } from "react-router-hash-link";

//comps
import Spinner from "../Spinner";
import Item from "./Item";
import Col from "./Item-col";
import Auth from "../Auth";

import Logo from "../../media/logo.svg";
//media
// import One from "../../media/preview/One.png";
import Two from "../../media/preview/two.png";
import Ty from "../../media/preview/thankyou.jpg";
// import Three from "../../media/preview/three.png";

import Img1 from "../../media/preview/1.jpg";
import Img2 from "../../media/preview/2.jpg";
import Img22 from "../../media/preview/22.png";
import Img3 from "../../media/preview/3.jpg";
import Img3a from "../../media/preview/3a.png";
import Img4 from "../../media/preview/4.jpg";
import Img4a from "../../media/preview/4a.png";
import Img5 from "../../media/preview/5.jpg";
import Img5a from "../../media/preview/5a.png";
import Img6 from "../../media/preview/6.jpg";
import Img7 from "../../media/preview/7.jpg";
import Img7a from "../../media/preview/7a.png";
import Img8 from "../../media/preview/8.jpg";
import Img9 from "../../media/preview/9.jpg";
import Img10 from "../../media/preview/10.jpg";
import Img10a from "../../media/preview/10a.png";
import Img11 from "../../media/preview/11.jpg";
import Img11a from "../../media/preview/11a.png";
import Img12 from "../../media/preview/12.jpg";
import Img13 from "../../media/preview/13.jpg";
import Img13a from "../../media/preview/13a.png";
import Img14 from "../../media/preview/14.jpg";
import Img15 from "../../media/preview/15.jpg";

const Comp = (props) => {
  const proj = [
    {
      title: "Our Mission",
      subtitle: "",
      text:
        "Our mission is to connect artists in need of funding with patrons willing to invest in them. As a result, Riffy has two distinct users: ",
      section: "Section",
      img: Img1,
      imgb: "",
      comp: Col,
    },
    {
      title: "User's Journey",
      subtitle: "",
      text:
        "The layout of the platform is designed around connecting Artists and Patrons on the Project page. Both users take different paths, but their journeys end in the same place.",
      img: Img2,
      imgb: Img4a,
      comp: Col,
    },
    {
      title: "Beauty",
    },
    {
      title: "Components",
      subtitle: "",
      text:
        "Based on both user's needs, we designed specific, recurring components to make their experience as simple as possible.",
      img: Img22,
      imgb: Img3a,
      comp: Col,
    },
    {
      title: "Dashboard",
      section: "Spotlight",
      subtitle:
        "The spotlight gives users and easy way to browse new projects.",
      text:
        "The Dashboard is home to the latest projects and trending artists. It provides a home from which to browse curated content.",
      img: Img4,
      imgb: Img4a,
      comp: Item,
    },
    {
      title: "Project",
      section: "Project media",
      subtitle:
        "Depending on the type of project (song, album, etc) Artists can chose what media they would like to affiliate with their project.",
      text:
        "The product pages utilize statistics to help Patrons decide whether or not to invest. It also provides Artists with a platfrom to showcase their vision.",
      img: Img5,
      imgb: Img5a,
      comp: Item,
    },
    {
      title: "Portfolio",
      subtitle: "",
      text:
        "All of a Patron’s investments are aggregated on the portfolio page. This gives users a clear sense of how their investments are performing",
      img: Img6,
      imgb: "",
      comp: Item,
    },
    {
      title: "Discover",
      section: "Hoverplay",
      subtitle:
        "When hoverplay is toggled on, users can quickly vet an entire gallery of projects without leaving the page.",
      text:
        "The Discover page is designed to make finding and investing in projects as easy as possible.",
      img: Img7,
      imgb: Img7a,
      comp: Item,
    },
    {
      title: "Resources",
      subtitle: "",
      text:
        "To ensure the success of both Artists and Patrons, Riffy provides extensive resources, tools, and guides.",
      img: Img8,
      imgb: "",
      comp: Item,
    },
    {
      title: "Create",
      subtitle: "",
      text:
        "Artists are granted access to an entire slew of specialized tools. Chief among which is the projects page. This gives artists insight into the performance of their projects as well as demographic information.",
      img: Img9,
      imgb: "",
      comp: Item,
    },
    {
      title: "Project Builder",
      section: "Project Card",
      subtitle:
        "As the Artist builds their project, the project card follows them down the page to provide a preview of what they are creating.",
      text:
        "Creating a project and requesting funding is daunting for any Artist. The Project Builder is designed to make this process as easy as possible.",
      img: Img10,
      imgb: Img10a,
      comp: Item,
    },
    {
      title: "Profile",
      section: "Play Projects",
      subtitle:
        "Patrons are able to listen to a “playlist” of any Artist's projects.",
      text:
        "Artists are given full control over their profile. They are provided with optional metrics that may help attract investors.",
      img: Img11,
      imgb: Img11a,
      comp: Item,
    },
    {
      title: "The Future",
      text: "Riffy is being designed and built with the future in mind.",
      img: Img12,
      imgb: Img4a,
      comp: Col,
    },
  ];

  return !props.user ? (
    <Auth />
  ) : (
    <div className="gallery-wrap">
      {props.noWelcome ? (
        ""
      ) : (
        <div className="gallery-welcome flex-col flex-just-cent flex-align-cent">
          <h1>Welcome</h1>
          <p>Please allow a few minutes for us to get setup</p>
          <NavHashLink smooth to="/#beauty">
            <button>Begin</button>
          </NavHashLink>
        </div>
      )}

      {proj.map((item, i) => {
        let Comp = item.comp;
        if (i == 2)
          return (
            <div style={{ position: "relative" }} id={`slide-${i + 1}`}>
              <div className="gallery-item-index flex-align-cent">
                <NavHashLink smooth to={`/#slide-${i}`}>
                  <button className="icon-button">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 17L9.5 12L14.5 7"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </NavHashLink>
                <button className="icon-button button-number">{i + 1}</button>

                <NavHashLink smooth to={`/#slide-${i + 2}`}>
                  <button className="icon-button">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 7L14.5 12L9.5 17"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </NavHashLink>
              </div>

              <ImageFadeIn
                id="beauty"
                className="gallery-beautryshot"
                src={Two}
              />
            </div>
          );
        return (
          <Comp
            key={`slide-${i}`}
            title={item.title}
            subtitle={item.subtitle}
            text={item.text}
            img={item.img}
            imgb={item.imgb}
            index={i + 1}
            section={item.section}
          />
        );
      })}
      <ImageFadeIn id="ty" className="gallery-beautryshot" src={Ty} />
    </div>
  );
};
export default Comp;

//Thank you message
// <div className="thank-you">
//   <h1>Thank you</h1>
//   <p>
//     We sincerely appreciate you taking the time to review our proposal.
//   </p>
//   <img src={Logo} />
// </div>
