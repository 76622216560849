import React, { useState } from "react";
import "./style.css";
import ImageFadeIn from "react-image-fade-in";
import { NavHashLink } from "react-router-hash-link";

//media

const Comp = (props) => {
  return (
    <div className="gallery-item gallery-item-col" id={`slide-${props.index}`}>
      <div className="gallery-item-index flex-align-cent">
        {props.index > 1 ? (
          <NavHashLink smooth to={`/#slide-${props.index - 1}`}>
            <button className="icon-button">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 17L9.5 12L14.5 7"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </NavHashLink>
        ) : (
          ""
        )}
        <button className="icon-button button-number">{props.index} </button>

        <NavHashLink smooth to={`/#slide-${props.index + 1}`}>
          <button className="icon-button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 7L14.5 12L9.5 17"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </NavHashLink>
      </div>
      <div className="gallery-item-text ">
        <div>
          <h1>{props.title}</h1>
          <p>
            <span>{props.text}</span>
          </p>
        </div>
      </div>

      <ImageFadeIn src={props.img} />
    </div>
  );
};
export default Comp;
//{props.subtitle}
