import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import "./style.css";

//firebase
import firebase from "../../firebase";

//comps
import Spinner from "../Spinner";

//media

const Comp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, SetUser] = useState({ email: "", password: "" });
  // const history = useHistory();

  const signInUser = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    console.log(`${user.name}@riffy.io`);

    firebase
      .auth()
      .signInWithEmailAndPassword(`${user.name}@riffy.io`, user.password)
      .then(() => {
        setLoading(false);
        setErrorMessage("");
        // history.push("/account");
        console.log("it workded");
      })
      .catch((error) => {
        console.log(error.code, ": ", error.message);
        handleErrorMessage(error.message);
      });
  };

  const handleErrorMessage = (message) => {
    setLoading(false);
    setErrorMessage(message);
  };

  return (
    <form className="auth-wrap flex-col" onSubmit={(e) => signInUser(e)}>
      <h3>Login</h3>
      <input
        type="text"
        placeholder="Username"
        value={user.name}
        onChange={(e) =>
          SetUser({
            name: e.target.value,
            password: user.password,
          })
        }
      />
      <input
        type="password"
        placeholder="Password"
        value={user.password}
        onChange={(e) =>
          SetUser({
            name: user.name,
            password: e.target.value,
          })
        }
      />
      <div className="flex-just-rit">
        <button submit="true">
          {!loading ? (
            "Submit"
          ) : (
            <div className="flex-align-cent">
              <Spinner button={true} size={"24px"} />
              <span style={{ marginLeft: "12px" }}>Loading</span>
            </div>
          )}
        </button>
      </div>
    </form>
  );
};
export default Comp;
