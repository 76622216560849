import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import "./App.css";
import "./fonts.css";

//firebase
import firebase from "./firebase";

import Auth from "./comps/Auth";
import Gallery from "./comps/Gallery";
import GallerySml from "./comps/GallerySml";

import Logo from "./media/logo.svg";
import Close from "./media/Close.svg";
import More from "./media/More.svg";

import AuthedPg from "./pages/AuthedGallery.js";

import useDocumentScrollThrottled from "./useDocumentScrollThrottled";

const App = () => {
  const [user, setUser] = useState(null);
  const [drop, setDrop] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 0;

  //authentication
  firebase.auth().onAuthStateChanged(function (firebaseUser) {
    if (firebaseUser) {
      // console.log("USER: ", firebaseUser ? true : false);
      console.log(firebaseUser.getIdToken());
      setUser(firebaseUser);
    } else {
      // console.log("USER: ", firebaseUser ? true : false);
      setUser(null);
    }
  });

  const signout = () => {
    firebase.auth().signOut();
  };

  // <div className="flex-align-cent flex-just-cent">Light mode</div>
  const Drop = (
    <div className="drop-wrap flex-col">
      <div
        className="flex-align-cent flex-just-cent"
        onClick={() => {
          signout();
          setDrop(false);
        }}
      >
        Sign out
      </div>
    </div>
  );

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 100);
    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div className={`App ${user ? "authed" : ""}`}>
      <header
        className={`App-header ${shouldShowShadow ? "header-shadow" : ""} ${
          shouldHideHeader ? "header-hidden" : ""
        }`}
      >
        <img src={Logo} className="riffy-logo " alt="logo" />
        <div className="button-wrapper">
          <a href="mailto:">
            <button className="reg">Contact</button>
          </a>
          {!user ? (
            ""
          ) : (
            <button className="icon-button" onClick={(e) => setDrop(!drop)}>
              {!drop ? (
                <img src={More} className=" " alt="logo" />
              ) : (
                <img src={Close} className=" " alt="logo" />
              )}
            </button>
          )}

          {!drop ? "" : Drop}
        </div>
      </header>

      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <AuthedPg {...props} user={user} />}
        />
        <Route
          exact
          path="/y-combinator"
          render={(props) => <Gallery {...props} noWelcome={true} />}
        />
        <Route
          exact
          path="/presentation"
          render={(props) => (
            <GallerySml {...props} user={user} noWelcome={true} />
          )}
        />
      </Switch>
    </div>
  );
};

export default App;
