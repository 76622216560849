import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAMwsmgTL6XOe9x3L3XfEhsH7Lh9Cacr7A",
  authDomain: "riffy-web-app.firebaseapp.com",
  projectId: "riffy-web-app",
  storageBucket: "riffy-web-app.appspot.com",
  messagingSenderId: "407792617253",
  appId: "1:407792617253:web:8e964d78130d65251dbccc",
  measurementId: "G-NRD4J7JJVM",
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
