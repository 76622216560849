import React from "react";
import "./style.css";

const Spinner = (props) => {
  let height = props.size ? props.size : "36px";
  return (
    <svg
      style={{
        height: height,
        width: height,
      }}
      className="spinner"
      viewBox="0 0 50 50"
    >
      <circle
        style={{ stroke: props.button ? "#fff" : "var(--disabled-font-color)" }}
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  );
};

export default Spinner;
