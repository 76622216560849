//firebase
import firebase from "../firebase";

import Auth from "../comps/Auth";
import Gallery from "../comps/Gallery";

const App = (props) => {
  return !props.user ? <Auth /> : <Gallery noWelcome={true} />;
};

export default App;
